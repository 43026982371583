:root {
  scroll-behavior: smooth;
  --color-dark-gray: #212529;
  --color-dark-light-gray: rgba(33, 37, 41, 0.4);
  --color-red: #ce1212;
  --color-rosy-brown: #af7e7a;
  --color-scarlet: #ec2727;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-light-gray: #eeeeee;
  --color-onyx: #343a40;
  --color-charcoal-crey: #495057;
  --color-transparent: rgba(255, 255, 255, 0);
  --color-dark-grey-transparent: rgba(55, 55, 63, 0.1);
  --color-dark-indigo: rgb(26, 13, 171);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--color-rosy-brown), "Times New Roman", serif;
}

footer a {
  color: var(--color-dark-indigo);
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/*--------------------------------------------------------------
# Index - About Section
--------------------------------------------------------------*/
.about {
  background-color: var(--color-light-gray);
}

.about .button {
  background: var(--color-red);
  border: 0;
  padding: 12px 40px;
  color: var(--color-white);
  transition: 0.4s;
  border-radius: 50px;
}

.about .button:hover {
  background: var(--color-scarlet);
}

/*--------------------------------------------------------------
# Index - Download Section
--------------------------------------------------------------*/
.download {
  background-color: var(--color-light-gray);
}

.download .requirements {
  max-width: 393px;
  margin-bottom: 25px;
  padding: 25px;
  background: var(--color-scarlet);
  color: var(--color-white);
  height: auto;
}

.download .requirements h3 {
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 10px;
}

.download .requirements p {
  margin-bottom: 5px;
}

.download .requirements .download-button i {
  font-size: 14px;
}

.download .files .member {
  text-align: center;
  background: var(--color-white);
  padding: 40px 30px;
  width: 100%;
  height: 100%;
  border: 3px solid var(--color-dark-grey-transparent);
  transition: 0.3s;
}

.download .files .member h4 {
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 30px 0;
}

.download .files .member:hover {
  transform: scale(1.1);
}

.download .files .member .image {
  width: 50px;
  height: 50px;
}

@media (min-width: 992px) and (max-width: 1399px){
  .download .requirements {
    margin: auto auto 25px;
    width: 50%;
  }
}

@media (max-width: 991px){
  .download .requirements {
    margin: auto auto 25px;
  }
}

/*--------------------------------------------------------------
# Page 404
--------------------------------------------------------------*/
.page404 .button {
  background: var(--color-red);
  border: 0;
  padding: 12px 40px;
  color: var(--color-white);
  transition: 0.4s;
  border-radius: 50px;
  max-width: 300px;
}

.page404 .button:hover {
  color: var(--color-white);
  background: var(--color-scarlet);
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  background-color: var(--color-light-gray);
  margin-top: auto;
}

/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
.navbar .navigation-hamburger {
  color: var(--color-transparent) !important;
}

@media (max-width: 300px){
  .navbar .hide-text span {
    display: none;
  }

  .navbar .navbar-title-change-text::after {
    content: "MSR";
  }
}

@media (min-width: 576px) {
  .navbar {
    max-height: 50px;
  }
}

.navbar img {
  min-width: 40px;
  min-height: 40px;
}

/*--------------------------------------------------------------
# Form Section
--------------------------------------------------------------*/
.form {
  background-color: var(--color-light-gray);
}

.form .input-form .input:focus {
  border-color: var(--color-red);
}

.form .input-form .label {
  display: flex;
  align-items: center;
}

.form .input-form .input {
  box-shadow: none;
  height: 48px;
}

.form .input-form .button {
  background: var(--color-red);
  border: 0;
  padding: 12px 40px;
  color: var(--color-white);
  transition: 0.4s;
  border-radius: 50px;
}

.form .input-form .button:hover {
  background: var(--color-scarlet);
}

/*--------------------------------------------------------------
# News Section
--------------------------------------------------------------*/
.news {
  background-color: var(--color-light-gray);
}

.news .news-card {
  transition: all 0.2s ease-in-out 0s;
}

.news .news-card:hover {
  transform: scale(1.1);
}

.news .image {
  height: 150px;
  width: 100%;
}

.news .button {
  background: var(--color-red);
  border: 0;
  padding: 12px 40px;
  color: var(--color-white);
  transition: 0.4s;
  border-radius: 50px;
  text-decoration: none;
}

.news .button:hover {
  background: var(--color-scarlet);
}

/*--------------------------------------------------------------
# News - Single Section
--------------------------------------------------------------*/
.single {
  background-color: var(--color-light-gray);
}

.single .image {
  max-height: 400px;
}

.single .news-image {
  max-height: 300px;
  max-width: 70%;
}

.single .single-news {
  max-height: 200px !important;
}

.single .button {
  background: var(--color-red);
  border: 0;
  padding: 12px 40px;
  color: var(--color-white);
  transition: 0.4s;
  border-radius: 50px;
  text-decoration: none;
}

.single .button:hover {
  background: var(--color-scarlet);
}

/*--------------------------------------------------------------
# Popup
--------------------------------------------------------------*/
.popup .text-right {
  text-align: right;
}

.popup button {
  margin-right: 10px;
}

/*--------------------------------------------------------------
# Settings
--------------------------------------------------------------*/
.settings .button {
  background: var(--color-red);
  border: 0;
  padding: 12px 40px;
  color: var(--color-white);
  transition: 0.4s;
  border-radius: 50px;
  text-decoration: none;
}

.settings .button:hover {
  background: var(--color-scarlet);
}

.settings .input-label {
  display: block;
  font-size: large;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px;
}

.settings .input-form .input:focus {
  border-color: var(--color-red);
}

.settings .input-form .label {
  display: flex;
  align-items: center;
}

.settings .input-form .input {
  box-shadow: none;
  height: 48px;
}

.settings .image {
  min-width: 100px;
  min-height: 100px;
  max-width: 100px;
  max-height: 100px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
}

/*--------------------------------------------------------------
# Home
--------------------------------------------------------------*/
.home .image {
  min-width: 100px;
  min-height: 100px;
  max-width: 100px;
  max-height: 100px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
}

.home .overview-box .box-header {
  background: var(--color-red);
  border: 0;
  padding: 12px 40px;
  color: var(--color-white);
  transition: 0.4s;
  text-decoration: none;
}

.home .overview-box .box-header:hover {
  background: var(--color-scarlet);
}

.home .overview-box .box-body {
  max-height: 414px;
}

.home .overview-box .box-body .graph-height {
  height: 100px;
  max-height: 100px;
}

.home .overview-box .overview {
  display: flex;
  flex-wrap: wrap;
}

.home .overview-box .overview-box {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.home .overview-box .box-header {
  color: #fff;
  font-size: 1.25rem;
}

.home .overview-box .box-body {
  padding: 1rem;
  flex: 1;
}

.home .overview-box .box-footer {
  margin-top: 0;
  margin-bottom: 0;
  border-top: 1px solid #dee2e6;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.home .overview-box .footer-cell {
  flex: 1;
  text-align: center;
  padding: 0.5rem;
}

.home .overview-box {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.home .box-body {
  flex: 1;
}

.home .button {
  background: var(--color-red);
  border: 0;
  padding: 12px 40px;
  color: var(--color-white);
  transition: 0.4s;
  border-radius: 50px;
  text-decoration: none;
}

.home .button:hover {
  background: var(--color-scarlet);
}

@media (min-width: 760px) and (max-width: 1000px) {
  .home .hide-text span {
    display: none;
  }

  .home .title-change-text::after {
    content: "T/P";
  }
}

@media (max-width: 768px) {
  .home .overview-box .box-footer {
    flex-direction: column;
  }
}

/*--------------------------------------------------------------
# Weapons And Missions
--------------------------------------------------------------*/
.statistics .table-active {
  --bs-table-bg-state: var(--color-light-gray) !important;
}

.statistics .pointer {
  cursor: pointer;
}

.statistics .grayscale-filter {
  filter: grayscale(100%) brightness(90%);
}

.statistics .aside-fixed {
  position: sticky;
  top: 50px;
  height: fit-content;
  z-index: 1000;
}

.statistics .weapon-image {
  max-width: 100px;
  max-height: 50px;
}

.statistics .mission-image {
  max-width: 200px;
  max-height: 100px;
}

.statistics .star-image {
  max-width: 50px;
}

.statistics .center-vertically {
  margin-top: 25px;
}

.statistics .weapon-selected {
  max-height: 150px;
}

.statistics .button {
  background: var(--color-red);
  border: 0;
  padding: 5px 20px;
  color: var(--color-white);
  transition: 0.4s;
  border-radius: 50px;
  text-decoration: none;
}

.statistics .button:hover {
  background: var(--color-scarlet);
}

.statistics .mission-link {
  color: var(--color-red);
}

.statistics .win {
  color: var(--color-rosy-brown);
}

.statistics .lost {
  color: var(--color-red);
}

.statistics-single .statistics-outer {
  max-height: 264px;
  overflow-y: auto;
  width: 100%;
}

@media (max-width: 991px) {
  .statistics-single .statistics {
    margin: 0 auto;
    text-align: left;
    display: block;
    max-width: 300px;
  }
}

/*--------------------------------------------------------------
# Notification
--------------------------------------------------------------*/
.notification .notification-container {
  background-color: var(--color-onyx);
  color: var(--color-white);
  padding: 20px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.notification .notification-container.customize-mode {
  background-color: var(--color-charcoal-crey);
  padding: 25px;
}

.notification .notification-text {
  font-size: 14px;
  line-height: 1.6;
}

.notification .button-group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.notification .button {
  background: var(--color-red);
  border: 0;
  padding: 12px 40px;
  color: var(--color-white);
  transition: 0.4s;
  border-radius: 50px;
  text-decoration: none;
}

.notification .button:hover {
  background: var(--color-scarlet);
}

/*--------------------------------------------------------------
# Table
--------------------------------------------------------------*/
.table th {
  min-width: 100px;
}

.table th:first-child {
  min-width: 50px;
}